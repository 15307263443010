// styling
import styles from "./style.module.scss";

// components
import WidgetHeader from "@components/WidgetHeader";
import CopyField from "@components/CopyField";
import LabelField from "@components/LabelField";
import StyledSelect from "@ui/StyledSelect";
import OperationTotal from "@components/OperationTotal";
import Spring from "@components/Spring";
import CurrencyLabel from "@ui/CurrencyLabel";
import FooterText from "@components/FooterText";

// hooks
import { useEffect, useState } from "react";
import { useModal } from "@contexts/modalContext";
import { useNavigate } from "react-router-dom";

// utils
import { memo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// assets
import iconLight from "@assets/icons/icon-deposit-light.svg";
import iconDark from "@assets/icons/icon-deposit-dark.svg";
import QRBTC from "@assets/btc.jpeg";
import QRERC20 from "@assets/erc20.jpeg";
import QRTRC20 from "@assets/trc20.jpeg";

// data placeholder
import { CURRENCIES, MERCHANTS, CARD_PAYMENT_METHOD } from "@constants/currencies";
import axios from "axios";
import { processDeposit } from "services/apiService";
import Loader from "@components/Loader";
import CardPaymentFooter from "@components/CardPaymentFooter";

const MySwal = withReactContent(Swal);

const CardPayment = () => {
  const [currency, setCurrency] = useState({
    value: CURRENCIES[0].value,
    label: (
      <CurrencyLabel icon={CURRENCIES[0].icon} label={CURRENCIES[0].label} />
    ),
    name: CURRENCIES[0].label,
  });
  const [merchant, setMerchant] = useState(CARD_PAYMENT_METHOD[0]);
  const { handleOpen } = useModal();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState(QRBTC);
  const [walletAddress, setWalletAddress] = useState("bc1qptmchg5wmr35s9h7npnm297zu5m9vhe2qgh07y");
  const navigate = useNavigate();

  useEffect(() => {
    // Update QR code and wallet address based on the selected merchant
    switch (merchant.value) {
      case 'BTC':
        setQrCode(QRBTC); // Update with the actual path to the Bitcoin QR code image
        setWalletAddress('bc1qptmchg5wmr35s9h7npnm297zu5m9vhe2qgh07y');
        break;
      case 'USDT(ERC20)':
        setQrCode(QRERC20); // Update with the actual path to the USDT ERC20 QR code image
        setWalletAddress(' 0x5dC01F37bD10c23E3F3801d166709CD32f492297');
        break;
      case 'USDT(TRC20)':
        setQrCode(QRTRC20); // Update with the actual path to the USDT TRC20 QR code image
        setWalletAddress('TYfw6JHuCvTgnZ8dyTjdB7sYLkn91pZBxR');
        break;
      default:
        setQrCode(QRBTC);
        setWalletAddress('bc1qptmchg5wmr35s9h7npnm297zu5m9vhe2qgh07y');
    }
  }, [merchant]);

 
  return (
    <Spring className="widget">
      <WidgetHeader title="Card Payment" iconLight={iconLight} iconDark={iconDark} />
      <div className={`${styles.container} ${styles.col2} widget_body card`}>
        {loading ? (
          <Loader visible /> // Render your loading spinner component here
        ) : (
          <div className="d-flex flex-column g-16">
            <div className="d-flex flex-column g-16">
              {/* <div className="field-group"> */}
                <LabelField
                  id="payment-amount"
                  label="Amount(USD)"
                  // placeholder="8000.00"
                  value="$ 8,000.00"
                  // onChange={(e) => setAmount(e.target.value)}
                  readonly
                />
                {/* <StyledSelect
                  value={currency}
                  onChange={setCurrency}
                  options={CURRENCIES}
                  variant="currency"
                /> */}
              {/* </div> */}
              <LabelField
                id="deposit-merchant"
                label="Payment Method"
                customInput={
                  <StyledSelect
                    id="deposit-merchant"
                    value={merchant}
                    onChange={setMerchant}
                    options={CARD_PAYMENT_METHOD}
                  />
                }
              />
            </div>
            <div className="d-flex flex-column g-8">
              {/* <OperationTotal currency={currency.name} /> */}
              <CardPaymentFooter />
            </div>
            {/* <button className="btn" onClick={handleDeposit}>
              Continue
            </button> */}
            {/* <button className="btn" onClick={() => handleOpen('payment')}>Continue</button> */}
          </div>
        )}
        <div className={styles.qr}>
          <img className={`${styles.qr_code} qr-code`} src={qrCode} alt="QR code" />
          <CopyField
            label="Address"
            value={walletAddress}
            masked
          />
        </div>
      </div>
    </Spring>
  );
};

export default memo(CardPayment);
