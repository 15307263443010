// GA
import ReactGA from "react-ga4";

// utils
import { lazy, Suspense } from "react";

// styles
import ThemeStyles from "@styles/theme";
import "./style.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "react-grid-layout/css/styles.css";

// contexts
import { SidebarProvider } from "@contexts/sidebarContext";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "@contexts/modalContext";

// hooks
import { useThemeProvider } from "@contexts/themeContext";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";

// components
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "@components/Loader";
import Sidebar from "@components/Sidebar";
import BottomNav from "@components/BottomNav";
import DepositPage from "@pages/Deposit";
import Withdrawal from "@pages/Withdraw";
import TransferPage from "@pages/Transfer";
import Profile from "@pages/Profile";
import TransactionDetailsPage from "@pages/TransactionDetail";
import CardDetailsPage from "@pages/CardDetails";
// import SettingsPage from "@pages/Settings";
import CardPaymentPage from "@pages/CardPayment";
import { WithdrawalProvider } from "@contexts/withdrawalContext";
import LoanPage from "@pages/Loan";
import KycPage from "@pages/KycPage";
import TransactionsPage from "@pages/Transactions";
// import Dashboard from "@pages/Dashboard";
import BuyGoldPage from "@pages/BuyGold";
import TransactionLimits from "@pages/TransactionLimits";
import TransactionLimitDetails from "@pages/TransactionLimitsDetails";
import ProtectedRoute from "@components/ProtectedRoute";
const SettingsPage = lazy(() => import("@pages/Settings"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Trade = lazy(() => import("@pages/Trade"));
const Actions = lazy(() => import("@pages/Actions"));
const Wallet = lazy(() => import("@pages/Wallet"));
const NFT = lazy(() => import("@pages/NFT"));
const Collections = lazy(() => import("@pages/Collections"));
const SignIn = lazy(() => import("@pages/SignIn"));
const SignUp = lazy(() => import("@pages/SignUp"));
const Verification = lazy(() => import("@pages/Verification"));

const App = () => {
  const appRef = useRef(null);
  const { theme } = useThemeProvider();
  const { width } = useWindowSize();

  // useEffect(() => {
  //   appRef.current && appRef.current.scrollTo(0, 0);
  // }, []);

  // const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
  // gaKey && ReactGA.initialize(gaKey);

  return (
    <SidebarProvider>
      <ThemeProvider theme={{ theme: theme }}>
        <ThemeStyles />
        <ToastContainer
          theme="colored"
          autoClose={2000}
          toastStyle={{ borderRadius: 4 }}
        />
        <WithdrawalProvider>
          <ModalProvider>
            <div ref={appRef}>
              {width < 768 ? <BottomNav /> : <Sidebar />}
              <Suspense fallback={<Loader visible />}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/trade"
                    element={
                      <ProtectedRoute>
                        <Trade />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/actions"
                    element={
                      <ProtectedRoute>
                        <Actions />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/deposit"
                    element={
                      <ProtectedRoute>
                        <DepositPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/withdraw"
                    element={
                      <ProtectedRoute>
                        <Withdrawal />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/buyGold"
                    element={
                      <ProtectedRoute>
                        <BuyGoldPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/transfer"
                    element={
                      <ProtectedRoute>
                        <TransferPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/transactions"
                    element={
                      <ProtectedRoute>
                        <TransactionsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/transaction-limits"
                    element={
                      <ProtectedRoute>
                        <TransactionLimits />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/transaction-limit-details/:limitType"
                    element={
                      <ProtectedRoute>
                        <TransactionLimitDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/transaction-details/:id"
                    element={
                      <ProtectedRoute>
                        <TransactionDetailsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/card-details"
                    element={
                      <ProtectedRoute>
                        <CardDetailsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/card-payment"
                    element={
                      <ProtectedRoute>
                        <CardPaymentPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute>
                        <SettingsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/kyc"
                    element={
                      <ProtectedRoute>
                        <KycPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/loan"
                    element={
                      <ProtectedRoute>
                        <LoanPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/wallet"
                    element={
                      <ProtectedRoute>
                        <Wallet />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/nft"
                    element={
                      <ProtectedRoute>
                        <NFT />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collections"
                    element={
                      <ProtectedRoute>
                        <Collections />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/verification" element={<Verification />} />
                </Routes>
              </Suspense>
            </div>
          </ModalProvider>
        </WithdrawalProvider>
      </ThemeProvider>
    </SidebarProvider>
  );
};

export default App;
