// // components
// import WidgetHeader from "@components/WidgetHeader";
// import LabelField from "@components/LabelField";
// import QRField from "@components/QRField";
// import OperationTotal from "@components/OperationTotal";
// import StyledSelect from "@ui/StyledSelect";
// import Spring from "@components/Spring";
// import FooterText from "@components/FooterText";
// import Loader from "@components/Loader";

// // hooks
// import { useState } from "react";
// import { useThemeProvider } from "@contexts/themeContext";
// import { useModal } from "@contexts/modalContext";
// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";

// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

// // constants
// import { CRYPTO_CURRENCIES, PAYMENT_METHOD } from "@constants/currencies";

// // assets
// import iconLight from "@assets/icons/icon-wallet-light.svg";
// import iconDark from "@assets/icons/icon-wallet-dark.svg";
// import CurrencyLabel from "@ui/CurrencyLabel";
// import { withdrawSchema } from "validation/validationSchema";
// import { processWithdrawal } from "services/apiService";
// import { Navigate, useNavigate } from "react-router-dom";

// const MySwal = withReactContent(Swal);

// const Withdraw = () => {
//   const { theme } = useThemeProvider();
//   const { handleOpen, handleClose } = useModal();
//   const navigate = useNavigate();
//   const [payment, setPayment] = useState(PAYMENT_METHOD[0]);
//   const [loading, setLoading] = useState(false);
//   const [currency, setCurrency] = useState({
//     value: CRYPTO_CURRENCIES[0].value,
//     label: (
//       <CurrencyLabel
//         icon={CRYPTO_CURRENCIES[0].icon}
//         label={CRYPTO_CURRENCIES[0].label}
//       />
//     ),
//     name: CRYPTO_CURRENCIES[0].label,
//   });

//   const handlePinSubmit = async () => {
//     setLoading(true);
//     try {
//       // Replace with your API call
//       const response = await processWithdrawal();
//       if (response.status === 200) {
//         MySwal.fire({
//           title: "Success!",
//           text: "Withdrawal request successful!",
//           icon: "success",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             navigate("/");
//           }
//         });
//       } else {
//         MySwal.fire({
//           title: "Error",
//           text: "Error processing withdrawal. Please try again.",
//           icon: "error",
//         });
//       }
//     } catch (error) {
//       console.error("Error processing withdrawal:", error);
//       MySwal.fire({
//         title: "Error",
//         text: "Error processing withdrawal. Please try again.",
//         icon: "error",
//       });
//     } finally {
//       setLoading(false);
//       handleClose(); // Close the PIN modal
//     }
//   };
//   // const [amount, setAmount] = useState("");
//   // const [bank, setBank] = useState("");
//   // const [accountNumber, setAccountNumber] = useState("");
//   // const [iban, setIban] = useState("");
//   // const [swift, setSwift] = useState("");
//   // const [wallet, setWallet] = useState("");

//   return (
//     <Spring className="widget">
//       <WidgetHeader
//         title="Withdraw"
//         iconLight={iconLight}
//         iconDark={iconDark}
//       />
//       <div className="widget_body card">
//         {loading ? (
//           <Loader /> // Render your loading spinner component here
//         ) : (
//           <div className="d-flex flex-column g-16">
//             {/* <LabelField
//             id="withdraw-address"
//             label="Address"
//             customInput={<QRField id="address" />}
//           /> */}
//             {/* <div className="field-group"> */}
//             <LabelField
//               id="withdraw-amount"
//               label="Amount"
//               placeholder="Min. amount: 0.001"
//             />
//             {/* <StyledSelect
//                   value={currency}
//                   onChange={setCurrency}
//                   options={CRYPTO_CURRENCIES}
//                   variant="currency"
//                 /> */}
//             {/* </div> */}
//             <LabelField
//               id="deposit-merchant"
//               label="Payment method"
//               customInput={
//                 <StyledSelect
//                   id="deposit-merchant"
//                   value={payment}
//                   onChange={setPayment}
//                   options={PAYMENT_METHOD}
//                 />
//               }
//             />
//             {payment.value === "bank" ? (
//               <>
//                 <LabelField
//                   id="withdraw-address"
//                   label="Bank Name"
//                   placeholder="Enter Bank Name"
//                 />
//                 <LabelField
//                   id="withdraw-address"
//                   label="Account Number"
//                   placeholder="Enter Account Number"
//                 />
//                 <p
//                   id="withdraw-address"
//                   style={{ marginTop: -14, marginLeft: 10, color: "red" }}
//                 >
//                   error
//                 </p>
//                 {/* <LabelField
//                 id="withdraw-address"
//                 label="Iban"
//                 placeholder="Enter Iban code"
//               /> */}
//                 <LabelField
//                   id="withdraw-address"
//                   label="Swift Code"
//                   placeholder="Enter swift code"
//                 />
//               </>
//             ) : (
//               <LabelField
//                 id="withdraw-address"
//                 label="Wallet Address"
//                 placeholder="Enter crypto wallet address"
//               />
//             )}
//           </div>
//         )}
//         <div className="d-flex flex-column g-8">
//           {/* <OperationTotal fee={1.2} currency={currency.name} /> */}
//           {/* <FooterText /> */}
//         </div>
//         <button
//           className={`btn ${theme === "light" ? "btn--invert" : ""}`}
//           onClick={() => handleOpen("pin")}
//         >
//           Withdraw
//         </button>
//       </div>
//     </Spring>
//   );
// };

// export default Withdraw;


// Withdraw.js
import WidgetHeader from "@components/WidgetHeader";
import LabelField from "@components/LabelField";
import QRField from "@components/QRField";
import OperationTotal from "@components/OperationTotal";
import StyledSelect from "@ui/StyledSelect";
import Spring from "@components/Spring";
import FooterText from "@components/FooterText";
import Loader from "@components/Loader";

// hooks
import { useState } from "react";
import { useThemeProvider } from "@contexts/themeContext";
import { useModal } from "@contexts/modalContext";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// constants
import { CRYPTO_CURRENCIES, PAYMENT_METHOD } from "@constants/currencies";

// assets
import iconLight from "@assets/icons/icon-wallet-light.svg";
import iconDark from "@assets/icons/icon-wallet-dark.svg";
import CurrencyLabel from "@ui/CurrencyLabel";
import { processWithdrawal } from "services/apiService";
import { useNavigate } from "react-router-dom";

// contexts
import { useWithdrawal } from "@contexts/withdrawalContext";

const MySwal = withReactContent(Swal);

const Withdraw = () => {
  const { theme } = useThemeProvider();
  const { handleOpen } = useModal();
  const navigate = useNavigate();
  const [payment, setPayment] = useState(PAYMENT_METHOD[0]);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState({
    value: CRYPTO_CURRENCIES[0].value,
    label: (
      <CurrencyLabel
        icon={CRYPTO_CURRENCIES[0].icon}
        label={CRYPTO_CURRENCIES[0].label}
      />
    ),
    name: CRYPTO_CURRENCIES[0].label,
  });
  const { setWithdrawalData } = useWithdrawal();

  const [formData, setFormData] = useState({
    amount: "",
    paymentMethod: PAYMENT_METHOD[0],
    bankName: "",
    accountNumber: "",
    swiftCode: "",
    walletAddress: "",
    note: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // console.log(formData)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setWithdrawalData(formData);
    handleOpen("pin");
  };

  return (
    <Spring className="widget">
      <WidgetHeader
        title="Withdraw"
        iconLight={iconLight}
        iconDark={iconDark}
      />
      <div className="widget_body card">
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit} className="d-flex flex-column g-16">
            <LabelField
              id="withdraw-amount"
              label="Amount"
              placeholder="Min. amount: 0.001"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
            <LabelField
              id="deposit-merchant"
              label="Withdrawal method"
              customInput={
                <StyledSelect
                  id="deposit-merchant"
                  value={payment}
                  onChange={(val) => {
                    setPayment(val);
                    setFormData((prev) => ({
                      ...prev,
                      paymentMethod: val,
                    }));
                  }}
                  options={PAYMENT_METHOD}
                />
              }
            />
            {payment.value === "bank" ? (
              <>
                <LabelField
                  id="withdraw-bank-name"
                  label="Bank Name"
                  placeholder="Enter Bank Name"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                />
                <LabelField
                  id="withdraw-account-number"
                  label="Account Number/IBAN"
                  placeholder="Enter Account Number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                />
                <LabelField
                  id="withdraw-swift-code"
                  label="Swift Code"
                  placeholder="Enter Swift Code"
                  name="swiftCode"
                  value={formData.swiftCode}
                  onChange={handleChange}
                />
              </>
            ) : (
              <LabelField
                id="withdraw-wallet-address"
                label="Wallet Address"
                placeholder="Enter crypto wallet address"
                name="walletAddress"
                value={formData.walletAddress}
                onChange={handleChange}
              />
            )}
            <LabelField
                id="withdraw-wallet-address"
                label="Description"
                placeholder="Note"
                name="note"
                value={formData.note}
                onChange={handleChange}
              />
            <button type="submit" className={`btn ${theme === "light" ? "btn--invert" : ""}`}>
              Withdraw
            </button>
          </form>
        )}
      </div>
    </Spring>
  );
};

export default Withdraw;

