import React, { useState, useEffect } from "react";
import styles from "./TransactionsPage.module.scss";
import Loader from "@components/Loader";
import { fetchUserTransactions } from "services/apiService";
import { useNavigate } from "react-router-dom";
import Spring from "@components/Spring";

const TransactionsWidget = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Replace with your API call
        const response = await fetchUserTransactions();
        setTransactions(response.data.transactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const handleRowClick = (transactionId) => {
    navigate(`/transaction-details/${transactionId}`);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <h1>Error</h1>
        <p>
          There was an error fetching the transactions. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Transactions</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr
              key={transaction._id}
              onClick={() => handleRowClick(transaction._id)}
            >
              <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
              <td>
                $
                {transaction.amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>{transaction.type}</td>
              <td>
                <span
                  className={`${styles.status} ${
                    styles[transaction.status.toLowerCase()]
                  }`}
                >
                  {transaction.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsWidget;
