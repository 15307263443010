// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
// import Deposit from '@widgets/Deposit';
// import TransactionDetails from '@widgets/TransactionDetails';
// import Withdraw from '@widgets/Withdraw';
// import Market from '@widgets/Market';
// import Stacking from '@widgets/Stacking';
// import Exchange from '@widgets/Exchange';
// import AMLCheck from '@widgets/AMLCheck';
import TransactionLimitsPage from '@widgets/TransactionLimits';

const widgets = {
    transactionlimits: <TransactionLimitsPage/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const TransactionLimits = () => {
    return (
        <AppLayout>
            <PageHeader title="Transaction Limits"/>
            <AppGrid id="transactionDetails" widgets={widgets}/>
        </AppLayout>
    )
}

export default TransactionLimits