import styles from "./TransactionDetails.module.scss";
import WidgetHeader from "@components/WidgetHeader";
import IconLight from "@assets/icons/icon-history-light.svg"
import IconDark from "@assets/icons/icon-history-light.svg"
import Spring from "@components/Spring";
import { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchTransactionDetails } from "services/apiService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "@components/Loader";

const MySwal = withReactContent(Swal);

const TransactionDetails = () => {
    const { id } = useParams();
  // const transactionId = "668336c4e94886a8e19babb2";
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTransactionDetails(id);
        // console.log(response.data.transaction);
        if (response.status === 200) {
          setTransaction(response.data.transaction);
          // console.log(response)
        } else {
          MySwal.fire({
            title: "Error",
            text: "Unable to fetch transaction details",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching transaction details:", error);
        MySwal.fire({
          title: "Error",
          text: "Error fetching transaction details",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <Loader visible />;
  }

  if (!transaction) {
    return (
      <Spring className="widget">
        <WidgetHeader title="Transaction Details" iconLight={IconLight} iconDark={IconDark}/>
        <div className={`${styles.container} ${styles.col2} widget_body card`}>
          <p style={{textAlign: 'center', fontSize: '20px'}}>Error fetching your transaction details</p>
        </div>
      </Spring>
    );
  }

  return (
    <Spring className="widget">
      <WidgetHeader title="Transaction Details" iconLight={IconLight} iconDark={IconDark}/>
      <div className={`${styles.container} widget_body card`}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailItem}>
            <span className={styles.label}>Transaction ID:</span>
            <span className={styles.value}>{transaction._id}</span>
            {/* <span className={styles.value}>237634764</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>Amount:</span>
            <span className={styles.value}>{transaction.amount} USD</span>
            {/* <span className={styles.value}>1000</span>  */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>Type:</span>
            <span className={styles.value}>{transaction.type}</span>
            {/* <span className={styles.value}>Transfer</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>Status:</span>
            <span className={styles.value}>{transaction.status}</span>
            {/* <span className={styles.value}>Success</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>Description:</span>
            <span className={styles.value}>{transaction.description}</span>
            {/* <span className={styles.value}>just for test</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>Date:</span>
            <span className={styles.value}>
              {new Date(transaction.createdAt).toLocaleString()}
            </span>
            {/* <span className={styles.value}>{new Date().toLocaleString()}</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>Transfer Status:</span>
            <span className={styles.value}>
              {transaction.transferStatus
                ? `${transaction.transferStatus}`
                : "N/A"}
            </span>
            {/* <span className={styles.value}>{'N/A'}</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>From:</span>
            <span className={styles.value}>
              {transaction.from
                ? `${transaction.from.name}(${transaction.from.accountNumber})`
                : "N/A"}
            </span>
            {/* <span className={styles.value}>{'N/A'}</span> */}
          </div>
          <div className={styles.detailItem}>
            <span className={styles.label}>To:</span>
            <span className={styles.value}>
              {transaction.to
                ? `${transaction.to.name}(${transaction.to.accountNumber})`
                : "N/A"}
            </span>
            {/* <span className={styles.value}>{'N/A'}</span> */}
          </div>
        </div>
      </div>
    </Spring>
  );
};

export default memo(TransactionDetails);
