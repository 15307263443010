// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Deposit from '@widgets/Deposit';
import TransactionDetails from '@widgets/TransactionDetails';
import Withdraw from '@widgets/Withdraw';
import Market from '@widgets/Market';
import Stacking from '@widgets/Stacking';
import Exchange from '@widgets/Exchange';
import AMLCheck from '@widgets/AMLCheck';
import CardDetails from '@widgets/CardDetails';

const widgets = {
    cardDetails: <CardDetails/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const CardDetailsPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Card Details"/>
            <AppGrid id="cardDetails" widgets={widgets}/>
        </AppLayout>
    )
}

export default CardDetailsPage