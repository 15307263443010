const LINKS = [
    {path: '/', mobile: 'Home', desktop: 'Dashboard', icon: 'home'},
    {path: '/card-details', mobile: 'My Card', desktop: 'My Card', icon: 'wallet'},
    // {path: '/actions', desktop: 'Actions', icon: 'actions'},
    {path: '/buyGold', mobile: 'Buy Gold', desktop: 'Buy Gold', icon: 'actions'},
    // {path: '/actions', mobile: 'Buy Gold', desktop: 'Buy Gold', icon: 'actions'},
    // {path: '/wallet', desktop: 'Wallet', icon: 'wallet'},
    // {path: '/nft', desktop: 'NFT', icon: 'nft'},
    // {path: '/collections', mobile: 'Explore', desktop: 'Collections', icon: 'collections'},
    {path: '/settings', mobile: 'Settings', desktop: 'Settings', icon: 'collections'},
]

export default LINKS