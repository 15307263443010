// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Deposit from '@widgets/Deposit';
// import Withdraw from '@widgets/Withdraw';
// import Market from '@widgets/Market';
// import Stacking from '@widgets/Stacking';
// import Exchange from '@widgets/Exchange';
// import AMLCheck from '@widgets/AMLCheck';

const widgets = {
    deposit: <Deposit/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const DepositPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Deposit"/>
            <AppGrid id="deposit" widgets={widgets}/>
        </AppLayout>
    )
}

export default DepositPage