// utils/auth.js
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // This ensures cookies are sent with requests
});



export const isAuthenticated = async () => {
  try {
    const response = await api.get("/api/dashboard/isAuthenticated");

    const data = await response.data;
    console.log("Authentication check response:", data);
    return data.isAuthenticated;
  } catch (error) {
    console.error("Error checking authentication:", error);
    return false;
  }
};
