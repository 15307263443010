import React, { useState } from 'react';
import styles from './KYCVerification.module.scss';
import { PatternFormat } from 'react-number-format';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import Loader from './Loader';
// import SuccessIcon from '@assets/icons/success.svg';
// import ErrorIcon from '@assets/icons/error.svg';
import { processKYC } from 'services/apiService';
import Loader from '@components/Loader';

const MySwal = withReactContent(Swal);

const KYCVerification = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      dateOfBirth: '',
      idNumber: '',
      idType: '',
      idDocument: null,
    },
    validationSchema: Yup.object({
      dateOfBirth: Yup.date().required('Date of birth is required'),
      idNumber: Yup.string().required('ID number is required'),
      idType: Yup.string().required('ID type is required'),
      idDocument: Yup.mixed().required('ID document is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Replace with your API call
        console.log(values)
        const response = await processKYC(values);
        if (response.status === 200) {
          MySwal.fire({
            title: 'Success!',
            text: 'KYC verification successful!',
            icon: 'success',
            // iconHtml: `<img src="${SuccessIcon}" alt="success" />`,
          });
        } else {
          MySwal.fire({
            title: 'Error',
            text: 'Error processing KYC. Please try again.',
            icon: 'error',
            // iconHtml: `<img src="${ErrorIcon}" alt="error" />`,
          });
        }
      } catch (error) {
        MySwal.fire({
          title: 'Error',
          text: 'Error processing KYC. Please try again.',
          icon: 'error',
        //   iconHtml: `<img src="${ErrorIcon}" alt="error" />`,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <h1>KYC Verification</h1>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        {/* <div className={styles.field}>
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            className={formik.errors.firstName ? styles.error : ''}
          />
          {formik.errors.firstName ? (
            <div className={styles.errorMessage}>{formik.errors.firstName}</div>
          ) : null}
        </div>
        <div className={styles.field}>
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            className={formik.errors.lastName ? styles.error : ''}
          />
          {formik.errors.lastName ? (
            <div className={styles.errorMessage}>{formik.errors.lastName}</div>
          ) : null}
        </div> */}
        <div className={styles.field}>
          <label htmlFor="dateOfBirth">Date of Birth</label>
          <input
            id="dateOfBirth"
            name="dateOfBirth"
            type="date"
            onChange={formik.handleChange}
            value={formik.values.dateOfBirth}
            className={formik.errors.dateOfBirth ? styles.error : ''}
          />
          {formik.errors.dateOfBirth ? (
            <div className={styles.errorMessage}>{formik.errors.dateOfBirth}</div>
          ) : null}
        </div>
        <div className={styles.field}>
          <label htmlFor="idType">ID Type</label>
          <select
            id="idType"
            name="idType"
            onChange={formik.handleChange}
            value={formik.values.idType}
            className={formik.errors.idType ? styles.error : ''}
          >
            <option value="">Select ID Type</option>
            <option value="passport">Passport</option>
            <option value="license">Driver's License</option>
            <option value="nationalId">National ID</option>
          </select>
          {formik.errors.idType ? (
            <div className={styles.errorMessage}>{formik.errors.idType}</div>
          ) : null}
        </div>
        <div className={styles.field}>
          <label htmlFor="idNumber">ID Number</label>
          <input
            id="idNumber"
            name="idNumber"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.idNumber}
            className={formik.errors.idNumber ? styles.error : ''}
          />
          {formik.errors.idNumber ? (
            <div className={styles.errorMessage}>{formik.errors.idNumber}</div>
          ) : null}
        </div>
        <div className={styles.field}>
          <label htmlFor="idDocument">ID Document</label>
          <input
            id="idDocument"
            name="idDocument"
            type="file"
            onChange={(event) => {
              formik.setFieldValue('idDocument', event.currentTarget.files[0]);
            }}
            className={formik.errors.idDocument ? styles.error : ''}
          />
          {formik.errors.idDocument ? (
            <div className={styles.errorMessage}>{formik.errors.idDocument}</div>
          ) : null}
        </div>
        <button type="submit" className="btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default KYCVerification;
