import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import styles from './style.module.scss';
import { fetchTransactionLimits } from 'services/apiService';
import { capitalizeFirstLetter } from '@utils/helpers';

const TransactionLimitDetailsPage = () => {
  const [limits, setLimits] = useState(null);
  const { limitType } = useParams(); // 'deposit' or 'withdrawal'
  const navigate = useNavigate();
  console.log(limitType)

  useEffect(() => {
    const fetchLimits = async () => {
      try {
        const response = await fetchTransactionLimits(limitType);
        // console.log(response)
        setLimits(response.data.data);
      } catch (error) {
        console.error('Failed to fetch transaction limits', error);
      }
    };

    fetchLimits();
  }, [limitType]);

  if (!limits) {
    return <div>Loading...</div>;
  }

  console.log(limits)
  // const limitType = type === 'deposit' ? 'Deposit' : 'Withdrawal';
  const dailyLimit = limits.dailyLimit;
  // const dailyLimit = 1000000;
  const dailySpent = limits.dailySpent;
  // const dailySpent = 1000;
  const monthlyLimit = limits.monthlyLimit;
  // const monthlyLimit = 1233;
  const monthlySpent = limits.monthlySpent;
  // const monthlySpent = 100000;

  const dailyRemaining = dailyLimit - dailySpent;
  const monthlyRemaining = monthlyLimit - monthlySpent;

  const calculatePercentage = (spent, limit) => (spent / limit) * 100;

  return (
    <div className={styles.container}>
      {/* <button onClick={() => navigate(-1)} className={styles.backButton}>
        ← Back
      </button> */}
      <h1>{capitalizeFirstLetter(limitType)} Limits</h1>
      <p>Limits for making payments into balances</p>
      <div className={styles.section}>
        <h2>Daily Limit: ${dailyLimit.toFixed(2)}</h2>
        {/* <h2>Daily Limit: $60000</h2> */}
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${calculatePercentage(dailySpent, dailyLimit)}%` }}
          ></div>
        </div>
        <p>${dailyRemaining.toFixed(2)} remaining</p>
      </div>
      <div className={styles.section}>
        <h2>Monthly Limit: ${monthlyLimit.toFixed(2)}</h2>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${calculatePercentage(monthlySpent, monthlyLimit)}%` }}
          ></div>
        </div>
        <p>${monthlyRemaining.toFixed(2)} remaining</p>
      </div>
    </div>
  );
};

export default TransactionLimitDetailsPage;
