// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
// import Deposit from '@widgets/Deposit';
// import Withdraw from '@widgets/Withdraw';
// import Market from '@widgets/Market';
// import Stacking from '@widgets/Stacking';
// import Exchange from '@widgets/Exchange';
// import AMLCheck from '@widgets/AMLCheck';
import Transfer from '@widgets/Transfer';

const widgets = {
    // deposit: <Deposit/>,
    transfer: <Transfer/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const TransferPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Transfer"/>
            <AppGrid id="transfer" widgets={widgets}/>
        </AppLayout>
    )
}

export default TransferPage