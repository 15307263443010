// import styles from "./style.module.scss";
// import WidgetHeader from "@components/WidgetHeader";
// import LabelField from "@components/LabelField";
// import Spring from "@components/Spring";
// import { memo, useState, useEffect } from "react";
// // import { fetchUserProfile, updateUserProfile } from "services/apiService";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Loader from "@components/Loader";
// import React from "react";

// const MySwal = withReactContent(Swal);

// const ProfileWidget = () => {
//   const [loading, setLoading] = useState(false);
//   const [userData, setUserData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     address: "",
//     // Add more fields as needed
//   });

//   useEffect(() => {
//     // const fetchData = async () => {
//     //   try {
//     //     // const response = await fetchUserProfile();
//     //     if (response.status === 200) {
//     //       setUserData(response.data);
//     //     } else {
//     //       MySwal.fire({
//     //         title: "Error",
//     //         text: "Unable to fetch profile data",
//     //         icon: "error",
//     //       });
//     //     }
//     //   } catch (error) {
//     //     console.error("Error fetching profile data:", error);
//     //     MySwal.fire({
//     //       title: "Error",
//     //       text: "Error fetching profile data",
//     //       icon: "error",
//     //     });
//     //   } finally {
//     //     setLoading(false);
//     //   }
//     // };
//     // fetchData();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setUserData({ ...userData, [name]: value });
//   };

//   const handleSubmit = async () => {
//     setLoading(true);
//     try {
//       //   const response = await updateUserProfile(userData);
//       //   if (response.status === 200) {
//       //     MySwal.fire({
//       //       title: "Success",
//       //       text: "Profile updated successfully",
//       //       icon: "success",
//       //     });
//       //   } else {
//       //     MySwal.fire({
//       //       title: "Error",
//       //       text: "Error updating profile",
//       //       icon: "error",
//       //     });
//       //   }
//     } catch (error) {
//       console.error("Error updating profile:", error);
//       MySwal.fire({
//         title: "Error",
//         text: "Error updating profile",
//         icon: "error",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Spring className="widget">
//       <WidgetHeader title="Profile" />
//       <div className={`${styles.container} ${styles.col2} widget_body card`}>
//         {loading ? (
//           <Loader visible />
//         ) : (
//           <div className="d-flex flex-column g-16">
//             <LabelField
//               id="name"
//               label="Name"
//               value={userData.name}
//               onChange={handleInputChange}
//               name="name"
//               placeholder={undefined}
//               customInput={undefined}
//             />
//             <LabelField
//               id="email"
//               label="Email"
//               value={userData.email}
//               onChange={handleInputChange}
//               name="email"
//               placeholder={undefined}
//               customInput={undefined}
//             />
//             <LabelField
//               id="phone"
//               label="Phone"
//               value={userData.phone}
//               onChange={handleInputChange}
//               name="phone"
//               placeholder={undefined}
//               customInput={undefined}
//             />
//             <LabelField
//               id="address"
//               label="Address"
//               value={userData.address}
//               onChange={handleInputChange}
//               name="address"
//               placeholder={undefined}
//               customInput={undefined}
//             />
//             {/* Add more fields as needed */}
//             <button className="btn" onClick={handleSubmit}>
//               Save Changes
//             </button>
//           </div>
//         )}
//       </div>
//     </Spring>
//   );
// };

// export default memo(ProfileWidget);

import React, { useEffect, useState } from "react";
import { FaEdit, FaCamera } from "react-icons/fa";
import styles from "./ProfilePage.module.scss";
import { fetchUserProfile } from "services/apiService";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "@components/Loader";

const MySwal = withReactContent(Swal);

const ProfilePage = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    profileImage: "",
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserProfile();
        if (response.status === 200) {
          const dob = new Date(response.data.profile?.dob).toLocaleDateString()
          setUser({
            profileImage: response.data.profile?.temp || response.data.profile?.image,
            name: response.data.profile?.user.name || response.data.profile?.userId.name,
            email: response.data.profile?.user.email || response.data.profile?.userId.email,
            phone: response.data.profile?.phoneNumber || '',
            dob: dob || '',
            address: response.data.user?.profileId?.address || response.data.profile?.address || '',
          });
        } else {
          MySwal.fire({
            title: "Error",
            text: "Unable to fetch profile data",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        MySwal.fire({
          title: "Error",
          text: "Error fetching profile data",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (field) => {
    const newValue = prompt(`Edit your ${field}:`, user[field]);
    if (newValue !== null) {
      setUser({ ...user, [field]: newValue });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUser({ ...user, profileImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  if(loading){
    return(
      <Loader />
    )
  }

  return (
    <div className={styles.profilePage}>
      <div className={styles.header}>
        <div className={styles.profileImageContainer}>
          <img
            src={user.profileImage}
            alt="Profile"
            className={styles.profileImage}
          />
          <label className={styles.cameraIcon}>
            <FaCamera />
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </label>
        </div>
        <h1 className={styles.name}>{user.name}</h1>
      </div>
      <div className={styles.details}>
        <div className={styles.detailItem}>
          <span className={styles.label}>Email:</span>
          <span className={styles.value}>{user.email}</span>
          {/* <FaEdit
            className={styles.editIcon}
            onClick={() => handleEdit("email")}
          /> */}
        </div>
        <div className={styles.detailItem}>
          <span className={styles.label}>Phone:</span>
          <span className={styles.value}>{user.phone}</span>
          {/* <FaEdit
            className={styles.editIcon}
            onClick={() => handleEdit("phone")}
          /> */}
        </div>
        <div className={styles.detailItem}>
          <span className={styles.label}>Date of Birth:</span>
          <span className={styles.value}>{user.dob}</span>
          {/* <FaEdit
            className={styles.editIcon}
            onClick={() => handleEdit("phone")}
          /> */}
        </div>
        <div className={styles.detailItem} >
          <span className={styles.label}>Address:</span>
          <span className={styles.value} style={{overflowWrap: 'break-word', wordBreak: 'break-word', marginLeft: '3px'}}>{user.address}</span>
          {/* <FaEdit
            className={styles.editIcon}
            onClick={() => handleEdit("address")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
