import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // This ensures cookies are sent with requests
});
console.log(api)
// const getUserId = () => {
//   if (process.env.NODE_ENV === "production") {
//     return process.env.REACT_APP_PROD_USER_ID;
//   } else {
//     return process.env.REACT_APP_DEV_USER_ID;
//   }
// };

export const fetchDashboardData = async () => {
  try {
    // const userId = getUserId();
    // const response = await api.get(`/api/dashboard/home/${userId}`);
    const response = await api.get(`/api/dashboard/home`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const fetchUserProfile = async () => {
  try {
    // const userId = getUserId();
    // const response = await api.get(`/api/dashboard/profile/${userId}`);
    const response = await api.get(`/api/dashboard/profile/`);
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const fetchUserNotifications = async () => {
  try {
    // const userId = getUserId();
    // const response = await api.get(`/api/dashboard/notifications/${userId}`);
    const response = await api.get(`/api/dashboard/notifications`);
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching user notifications:", error);
    throw error;
  }
};

export const processDeposit = async (amount, currency, merchant) => {
  try {
    // const userId = getUserId();
    const response = await api.post(`/api/dashboard/deposit`, {
      amount: parseFloat(amount),
      currency: currency.value,
      merchant: merchant.value,
    });

    // const response = await api.post(`/api/dashboard/deposit/${userId}`, {
    //   amount: parseFloat(amount),
    //   currency: currency.value,
    //   merchant: merchant.value,
    // });

    return response;
  } catch (error) {
    console.error("Error handling deposit transaction", error);
    throw error;
  }
};

export const processWithdrawal = async ({ withdrawalData, pin }) => {
  try {
    // const userId = getUserId();
    console.log(withdrawalData, pin);

    const data = {
      amount: parseFloat(withdrawalData.amount),
      // payment: withdrawalData.payment,
      note: withdrawalData.note,
      cryptoAddress: withdrawalData.walletAddress,
      bankName: withdrawalData.bankName,
      accountNumber: withdrawalData.accountNumber,
      swift: withdrawalData.swift,
      pin: pin,
    };

    if (withdrawalData.paymentMethod.value === "bank") {
      const response = await api.post(
        `/api/dashboard/withdrawal/?type=bank`,
        {
          amount: parseFloat(withdrawalData.amount),
          note: withdrawalData.note,
          bankName: withdrawalData.bankName,
          accountNumber: withdrawalData.accountNumber,
          swift: withdrawalData.swift,
          pin: pin,
        }
      );

      // const response = await api.post(
      //   `/api/dashboard/withdrawal/${userId}/?type=bank`,
      //   {
      //     amount: parseFloat(withdrawalData.amount),
      //     note: withdrawalData.note,
      //     bankName: withdrawalData.bankName,
      //     accountNumber: withdrawalData.accountNumber,
      //     swift: withdrawalData.swift,
      //     pin: pin,
      //   }
      // );

      console.log(response);
      return response;
    } else {
      const response = await api.post(
        `/api/dashboard/withdrawal/?type=crypto`,
        data
      );

      // const response = await api.post(
      //   `/api/dashboard/withdrawal/${userId}/?type=crypto`,
      //   data
      // );

      console.log(response);
      return response;
    }
  } catch (error) {
    console.error("Error handling withdrawal transaction", error);
    throw error;
  }
};

export const fetchBeneficiaryName = async (accountNumber) => {
  try {
    const response = await api.get(
      `/api/dashboard/beneficiary/${accountNumber}`
    );
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Error fetching beneficiary name:", error);
    return error;
  }
};

export const processTransfer = async (
  accountNumber,
  beneficiary,
  amount,
  note,
  currency
) => {
  try {
    // const userId = getUserId();
    console.log(amount, accountNumber, currency, note);
    const response = await api.post(`/api/dashboard/transfer`, {
      amount: parseFloat(amount),
      accountNumber: accountNumber,
      currency: currency,
      note: note,
    });

    // const response = await api.post(`/api/dashboard/transfer/${userId}`, {
    //   amount: parseFloat(amount),
    //   accountNumber: accountNumber,
    //   currency: currency,
    //   note: note,
    // });

    // console.log(response);

    return response;
  } catch (error) {
    console.error("Error handling Transfer transaction", error);
    throw error;
  }
};

export const fetchUserTransactions = async () => {
  try {
    // const userId = getUserId();
    const response = await api.get(`/api/dashboard/transactions`);
    // const response = await api.get(`/api/dashboard/transactions/${userId}`);

    // console.log(response);

    return response;
  } catch (error) {
    console.error("Error fetching Transaction details", error);
    throw error;
  }
};

export const fetchTransactionDetails = async (transactionId) => {
  try {
    // const userId = getUserId();
    const response = await api.get(
      `/api/dashboard/transaction-details/${transactionId}`
    );

    // const response = await api.get(
    //   `/api/dashboard/transaction-details/${transactionId}`
    // );

    console.log(response);

    return response;
  } catch (error) {
    console.error("Error fetching Transaction details", error);
    throw error;
  }
};

export const fetchCardDetails = async () => {
  try {
    // const userId = getUserId();
    // const response = await api.get(`/api/dashboard/card-details/${userId}`);
    const response = await api.get(`/api/dashboard/card-details`);

    console.log(response);

    return response;
  } catch (error) {
    console.error("Error fetching Card details", error);
    throw error;
  }
};

export const processLoan = async (amount, duration, description) => {
  try {
    // const userId = getUserId();
    const response = await api.post(`/api/dashboard/loan`, {
      amount: parseFloat(amount.value),
      duration: duration.value,
      note: description.value,
    });

    // const response = await api.post(`/api/dashboard/loan/${userId}`, {
    //   amount: parseFloat(amount.value),
    //   duration: duration.value,
    //   note: description.value,
    // });

    console.log(response);

    return response;
  } catch (error) {
    console.error("Error processing loan request", error);
    throw error;
  }
};

// export const processKYC = async (values) => {
//   try {
//     console.log(values.idDocument);
//     const userId = getUserId();
//     const response = await api.post(`/api/dashboard/kyc/${userId}`, {
//       dateOfBirth: values.dateOfBirth,
//       idNumber: values.idNumber,
//       idType: values.idType,
//       idDocument: values.idDocument,
//     });

//     console.log(response);

//     return response;
//   } catch (error) {
//     console.error("Error uploading kyc", error);
//     throw error;
//   }
// };

export const processKYC = async (values) => {
  try {
    // const userId = getUserId();

    const formData = new FormData();
    formData.append("dateOfBirth", values.dateOfBirth);
    formData.append("idNumber", values.idNumber);
    formData.append("idType", values.idType);
    formData.append("idDocument", values.idDocument);

    console.log(formData);
    console.log(values.idDocument.file);

    const response = await api.post(`/api/dashboard/kyc`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // const response = await api.post(`/api/dashboard/kyc/${userId}`, formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });

    return response;
  } catch (error) {
    console.error("Error uploading kyc", error);
    throw error;
  }
};

export const isProfileVerified = async () => {
  try {
    // const userId = getUserId();
    const response = await api.post(
      `/api/dashboard/checkVerificationStatus`
    );

    // const response = await api.post(
    //   `/api/dashboard/checkVerificationStatus/${userId}`
    // );
    if (response.status !== 200) {
      return false;
    }
    if (response.data.message === "Verified") {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const fetchTransactionLimits = async (type) => {
  try{
    // const userId = getUserId();
    // const response = await api.get(`/api/dashboard/getTransactionLimits/${type}/${userId}`);
    const response = await api.get(`/api/dashboard/getTransactionLimits/${type}`);

    // console.log(response)
    return response
  }catch(error){
    console.error("Error fetching transaction limits", error);
    throw error;
  }
}
