// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Deposit from '@widgets/Deposit';
// import ProfileWidget from '@widgets/ProfileWidget';
import ProfileWidget from '@widgets/ProfileWidget';
// import Market from '@widgets/Market';
// import Stacking from '@widgets/Stacking';
// import Exchange from '@widgets/Exchange';
// import AMLCheck from '@widgets/AMLCheck';
// import ProfileMain from '@widgets/ProfileMain';
// import TradingViewWidget from '@widgets/TradingViewWidget';

const widgets = {
    profile: <ProfileWidget/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const ProfilePage = () => {
    return (
        <AppLayout>
            <PageHeader title="Profile"/>
            <AppGrid id="actions" widgets={widgets}/>
        </AppLayout>
    )
}

export default ProfilePage