// components
import CollapsedText from '@components/CollapsedText';

// hooks
import useMeasure from 'react-use-measure';

const LoanFooterText = ({text}) => {
    const [ref, {width}] = useMeasure();
    const placeholder = `
       Note: You must have banked with us for at leat a year to be able to request loan.
    `;

    return (
        <div className="text-light" ref={ref}>
            <CollapsedText width={width} text={text ? text : placeholder} withButton={false}/>
        </div>
    )
}

export default LoanFooterText