import { useState, useEffect } from 'react';
import { isAuthenticated } from 'services/auth';
import Loader from './Loader';

const ProtectedRoute = ({ children }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      // const authStatus = await isAuthenticated();
      // setAuth(authStatus);
      if (process.env.NODE_ENV !== 'development') {
        const authStatus = await isAuthenticated();
        setAuth(authStatus);
      } else {
        setAuth(true); // Automatically set auth to true in development mode
      }
    };

    checkAuth();
  }, []);

  if (auth === null) {
    return <Loader visible={true} />; // or a loading spinner
  }

  if (!auth) {
    // Redirect to the login page if the user is not authenticated
    window.location.href = "https://swift-blink.com/swift/auth/login";
    return null; // This will prevent rendering of the child components
  }

  // If authenticated, render the child components (i.e., the protected component)
  return children;
};

export default ProtectedRoute;
