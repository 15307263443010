// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Deposit from '@widgets/Deposit';
// import ProfileWidget from '@widgets/ProfileWidget';
import ProfileWidget from '@widgets/ProfileWidget';
import Market from '@widgets/Market';
import Stacking from '@widgets/Stacking';
import Exchange from '@widgets/Exchange';
import AMLCheck from '@widgets/AMLCheck';
// import ProfileMain from '@widgets/ProfileMain';
import TradingViewWidget from '@widgets/TradingViewWidget';
import BuyGold from '@widgets/ComingSoon';

const widgets = {
    // wallet: <WalletOverview data={data?.user} />,
    // profile: <TradingViewWidget/>,
    comingSoon: <BuyGold />
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const BuyGoldPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Buy Gold"/>
            <AppGrid id="buygold" widgets={widgets}/>
        </AppLayout>
    )
}

export default BuyGoldPage