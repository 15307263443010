// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Deposit from '@widgets/Deposit';
import TransactionDetails from '@widgets/TransactionDetails';
import Withdraw from '@widgets/Withdraw';
import Market from '@widgets/Market';
import Stacking from '@widgets/Stacking';
import Exchange from '@widgets/Exchange';
import AMLCheck from '@widgets/AMLCheck';

const widgets = {
    transactionDetails: <TransactionDetails/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const TransactionDetailsPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Transaction Details"/>
            <AppGrid id="transactionDetails" widgets={widgets}/>
        </AppLayout>
    )
}

export default TransactionDetailsPage