// styling
import styles from "./style.module.scss";

// components
import WidgetHeader from "@components/WidgetHeader";
import CopyField from "@components/CopyField";
import LabelField from "@components/LabelField";
import StyledSelect from "@ui/StyledSelect";
import OperationTotal from "@components/OperationTotal";
import Spring from "@components/Spring";
import CurrencyLabel from "@ui/CurrencyLabel";
import FooterText from "@components/FooterText";

// hooks
import { useState } from "react";
import { useModal } from "@contexts/modalContext";

// utils
import { memo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// assets
import iconLight from "@assets/icons/icon-trade-light.svg";
import iconDark from "@assets/icons/icon-trade-dark.svg";
import QR from "@assets/qr-code.svg";

// data placeholder
import { CURRENCIES, MERCHANTS } from "@constants/currencies";
import { fetchBeneficiaryName, processTransfer } from "services/apiService";
import { useNavigate } from "react-router-dom";
import Loader from "@components/Loader";

const MySwal = withReactContent(Swal);

const Transfer = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState({
    value: CURRENCIES[0].value,
    label: (
      <CurrencyLabel icon={CURRENCIES[0].icon} label={CURRENCIES[0].label} />
    ),
    name: CURRENCIES[0].label,
  });
  const [merchant, setMerchant] = useState(MERCHANTS[0]);
  const { handleOpen } = useModal();
  const navigate = useNavigate();
  const [errorOccurred, setErrorOccurred] = useState(false); 

  const getBeneficiaryName = async (value) => {
    try {
      const beneficiary = await fetchBeneficiaryName(value);
      if (beneficiary.status === 200) {
        const response = beneficiary.data
        const name = response.data[0].name
        setBeneficiaryName(name);
        setErrorOccurred(false);
      } else {
        setBeneficiaryName("");
        setErrorOccurred(false);
      }
    } catch (error) {
      // console.log(error);
      setErrorOccurred(true);
    }
  };

  const handleAccountNumberChange = (e) => {
    try {
      const value = e.target.value;
      setAccountNumber(value);
      if (value.length === 10) {
        // Assuming account number length is 10
        getBeneficiaryName(value);
      } else {
        setBeneficiaryName("");
        setErrorOccurred(true);
      }
    } catch (error) {
      // console.log(error);
    //   setErrorOccurred(true);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await processTransfer(
        accountNumber,
        beneficiaryName,
        amount,
        currency.value,
        note
      );

      if (response.status === 200) {
        MySwal.fire({
          title: "Success!",
          text: "Transfer request successful!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Error processing transfer. Please try again.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error processing transfer:", error);
      Swal.fire({
        title: "Error",
        text: "Error processing transfer. Please try again.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spring className="widget">
      <WidgetHeader
        title="Transfer"
        iconLight={iconLight}
        iconDark={iconDark}
      />
      <div className={`${styles.container} ${styles.col2} widget_body card`}>
        {loading ? (
          <Loader visible /> // Render your loading spinner component here
        ) : (
          <div className="d-flex flex-column g-16">
            <div className="d-flex flex-column g-16">
              <LabelField
                id="account-number"
                label="Account Number"
                value={accountNumber}
                onChange={handleAccountNumberChange}
              />
              <LabelField
                id="beneficiary-name"
                label="Beneficiary Name"
                value={beneficiaryName}
                readOnly
              />
              {errorOccurred && accountNumber.length === 10 && (
                <p id="withdraw-address" style={{ marginTop: -14, marginLeft: 10, color: "red" }} className={styles.error}>
                  Beneficiary not found for this account number.
                </p>
              )}
              {/* <div className="field-group"> */}
                <LabelField
                  id="deposit-amount"
                  label="Amount"
                  placeholder="0.00"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                {/* <StyledSelect
                  value={currency}
                  onChange={setCurrency}
                  options={CURRENCIES}
                  variant="currency"
                /> */}
              {/* </div> */}
              <LabelField
                id="note"
                label="Description"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              {/* <LabelField
              id="deposit-merchant"
              label="Merchant"
              customInput={
                <StyledSelect
                  id="deposit-merchant"
                  value={merchant}
                  onChange={setMerchant}
                  options={MERCHANTS}
                />
              }
            /> */}
            </div>
            {/* <div className="d-flex flex-column g-8">
            <OperationTotal currency={currency.name} />
            <FooterText />
          </div> */}
            <button className="btn" onClick={handleSubmit}>
              Continue
            </button>
          </div>
        )}
      </div>
    </Spring>
  );
};

export default memo(Transfer);
