import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

const TransactionLimitsPage = () => {
    const navigate = useNavigate();

    const handleNavigation = (limitType) => {
        navigate(`/transaction-limit-details/${limitType}`);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Transaction Limits</h1>
            <div className={styles.limitItem} onClick={() => handleNavigation('withdrawal')}>
                <span className={styles.icon}>↑</span>
                <span className={styles.text}>Withdrawal Limits</span>
            </div>
            <div className={styles.limitItem} onClick={() => handleNavigation('transfer')}>
                <span className={styles.icon}>↓</span>
                <span className={styles.text}>Transfer Limits</span>
            </div>
        </div>
    );
};

export default TransactionLimitsPage;
