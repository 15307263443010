import React, { useEffect, useState } from 'react';
import styles from './BuyGold.module.scss';

const BuyGold = () => {
  // Countdown timer state
  const [timeLeft, setTimeLeft] = useState(48 * 60 * 60); // 48 hours in seconds

  // Countdown timer logic
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);

  // Function to format time
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  return (
    <div className={styles.container}>
      <h1>Coming Soon</h1>
      <div className={styles.countdown}>
        {/* <h2>Countdown to Launch</h2> */}
        {/* <p>{formatTime(timeLeft)}</p> */}
      </div>
    </div>
  );
};

export default BuyGold;
