// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
// import Deposit from '@widgets/Deposit';
// import Withdraw from '@widgets/Withdraw';
// import Market from '@widgets/Market';
// import Stacking from '@widgets/Stacking';
// import Exchange from '@widgets/Exchange';
// import AMLCheck from '@widgets/AMLCheck';
// import Transfer from '@widgets/Transfer';
import TransactionsWidget from '@widgets/TransactionsTest';
// import Transactions from '@widgets/Transactions';

const widgets = {
    // deposit: <Deposit/>,
    transactions: <TransactionsWidget/>,
    // transactions: <Transactions/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const TransactionsPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Transactions"/>
            <AppGrid id="transactions" widgets={widgets}/>
        </AppLayout>
    )
}

export default TransactionsPage