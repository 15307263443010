// styling
import styles from "./style.module.scss";

// components
import WidgetHeader from "@components/WidgetHeader";
// import CopyField from "@components/CopyField";
import LabelField from "@components/LabelField";
// import StyledSelect from "@ui/StyledSelect";
// import OperationTotal from "@components/OperationTotal";
import Spring from "@components/Spring";
// import CurrencyLabel from "@ui/CurrencyLabel";
// import FooterText from "@components/FooterText";

// hooks
import { useState } from "react";
import { useModal } from "@contexts/modalContext";
import { useNavigate } from "react-router-dom";

// utils
import { memo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// assets
import iconLight from "@assets/icons/icon-deposit-light.svg";
import iconDark from "@assets/icons/icon-deposit-dark.svg";
import QR from "@assets/qr-code.svg";

// data placeholder
import { processLoan } from "services/apiService";
import Loader from "@components/Loader";
import LoanFooterText from "@components/LoanFooterText";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MySwal = withReactContent(Swal);

const Loan = () => {
  const [amount, setAmount] = useState("");
  // const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");
  const [loanDate, setLoanDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await processLoan(amount, loanDate, description);

      if (response.status === 200) {
        MySwal.fire({
          title: "Success!",
          text: "Loan request was processed successfully!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        MySwal.fire({
          title: "Error",
          text: "Error processing loan. Please try again.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error processing loan:", error);
      MySwal.fire({
        title: "Error",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Spring className="widget">
      <WidgetHeader title="Loan" iconLight={iconLight} iconDark={iconDark} />
      <div className={`${styles.container} ${styles.col2} widget_body card`}>
        <div className="d-flex flex-column g-16">
          <div className="d-flex flex-column g-16">
            {/* <div className="field-group"> */}
            <LabelField
              id="loan-amount"
              label="Amount"
              placeholder="0.00"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            {/* <StyledSelect
                  value={currency}
                  onChange={setCurrency}
                  options={CURRENCIES}
                  variant="currency"
                /> */}
            {/* </div> */}
            <div className="field-wrapper">
              <label htmlFor="loan-date">Payback Date</label>
              <DatePicker
                selected={loanDate}
                onChange={(date) => setLoanDate(date)}
                className="field-input"
                id="loan-date"
              />
            </div>
            <LabelField
              id="loan-description"
              label="Description"
              placeholder=""
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column g-8">
            {/* <OperationTotal currency={currency.name} /> */}
            <LoanFooterText />
          </div>
          <button className="btn" onClick={handleSubmit}>
            Continue
          </button>
          {/* <button className="btn" onClick={() => handleOpen('payment')}>Continue</button> */}
        </div>
        {/* <div className={styles.qr}>
          <img className={`${styles.qr_code} qr-code`} src={QR} alt="QR code" />
          <CopyField
            label="Address"
            value="bc2w-qkj7-834-jj0h-458h-dwawp-q034"
            masked
          />
        </div> */}
      </div>
    </Spring>
  );
};

export default memo(Loan);
