import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import styles from "./CardDetailsPage.module.scss";
import ClipboardIcon from "@assets/icons/clipboard.png"; // Assume you have a clipboard icon in your assets
import MasterCardLogo from "@assets/images/mastercard-logo.png";
import { fetchCardDetails } from "services/apiService";
import withReactContent from "sweetalert2-react-content";
import Loader from "@components/Loader";
import iconLight from "@assets/icons/card.png"
// import { Spring } from "@react-spring/web";
import Spring from "@components/Spring";
import WidgetHeader from "@components/WidgetHeader";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const CardDetails = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate()

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCardDetails();
        console.log(response.data.card);
        if (response.status === 200) {
          setCard(response.data.card);
        } else {
          setError(true);
          // MySwal.fire({
          //   title: "Error",
          //   text: "Unable to fetch card details",
          //   icon: "error",
          // });
        }
      } catch (error) {
        console.error("Error fetching card details:", error);
        setError(true);
        // MySwal.fire({
        //   title: "Error",
        //   text: "Error fetching card details",
        //   icon: "error",
        // });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader visible />;
  }

  if (error) {
    return (
      <Spring className="widget">
        <WidgetHeader title="Card Details" iconLight={iconLight} iconDark={iconLight}/>
        <div className={styles.cardDetailsPage}>
          <div className={styles.activateCard}>
            <h2>Error loading your card details.</h2>
            <button
              className={styles.activateButton}
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>
          </div>
        </div>
      </Spring>
    );
  }

  return (
    <Spring className="widget">
      <WidgetHeader title="Card Details" iconLight={iconLight} iconDark={iconLight}/>
      <div className={styles.cardDetailsPage}>
        {card.status === "Active" ? (
          <>
            <div className={styles.cardMockup}>
              <div className={styles.cardHeader}>
                <img src={MasterCardLogo} alt="MasterCard" />
                <span>{card.user.balance} USD</span>
              </div>
              <div className={styles.cardBody}>
                <div className={styles.cardNumber}>{card.cardNumber}</div>
                <div className={styles.cardDetails}>
                  <span>Expiry: 06/26</span>
                  <span>CVV: {card.cvv}</span>
                </div>
              </div>
            </div>
            <div className={styles.cardInfo}>
              <div className={styles.infoItem}>
                <span>Card Holder Name:</span>
                <span>{card.user.name}</span>
                <img
                  src={ClipboardIcon}
                  alt="Copy"
                  onClick={() => handleCopy(card.user.name)}
                />
              </div>
              <div className={styles.infoItem}>
                <span>Card Number:</span>
                <span>{card.cardNumber}</span>
                <img
                  src={ClipboardIcon}
                  alt="Copy"
                  onClick={() => handleCopy(card.number)}
                />
              </div>
              <div className={styles.infoItem}>
                <span>Expiry Date:</span>
                <span>06/26</span>
                <img
                  src={ClipboardIcon}
                  alt="Copy"
                  onClick={() => handleCopy(card.expiryDate)}
                />
              </div>
              <div className={styles.infoItem}>
                <span>Billing Address:</span>
                <span>some, illioio, US</span>
                <img
                  src={ClipboardIcon}
                  alt="Copy"
                  onClick={() => handleCopy(card.billingAddress)}
                />
              </div>
              <div className={styles.infoItem}>
                <span>CVV:</span>
                <span>{card.cvv}</span>
                <img
                  src={ClipboardIcon}
                  alt="Copy"
                  onClick={() => handleCopy(card.cvv)}
                />
              </div>
              <div className={styles.infoItem}>
                <span>Zipcode:</span>
                <span>123444</span>
                <img
                  src={ClipboardIcon}
                  alt="Copy"
                  onClick={() => handleCopy(card.zipcode)}
                />
              </div>
            </div>
            {isCopied && <div className={styles.copiedMessage}>Copied!</div>}
          </>
        ) : (
          <div className={styles.activateCard}>
            <h2>Your card is not yet activated. Click the button below to activate your card</h2>
            <button
              className={styles.activateButton}
              onClick={() => navigate("/card-payment")}
            >
              Activate Card
            </button>
          </div>
        )}
      </div>
    </Spring>
  );
};

export default CardDetails;
