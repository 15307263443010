// components
import CollapsedText from '@components/CollapsedText';

// hooks
import useMeasure from 'react-use-measure';

const CardPaymentFooter = ({text}) => {
    const [ref, {width}] = useMeasure();
    const placeholder = `
       You are to pay the amount above to the crypto address provided below. Contact support to verify.
    `;

    return (
        <div className="text-light" ref={ref}>
            <CollapsedText width={width} text={text ? text : placeholder} withButton={false}/>
        </div>
    )
}

export default CardPaymentFooter