// components
import AppLayout from '@components/AppLayout';
import AppGrid from '@components/AppGrid';
import PageHeader from '@components/PageHeader';
import Deposit from '@widgets/Deposit';
import Withdraw from '@widgets/Withdraw';
import Market from '@widgets/Market';
import Stacking from '@widgets/Stacking';
import Exchange from '@widgets/Exchange';
import AMLCheck from '@widgets/AMLCheck';
import CardPayment from '../widgets/CardPayment';

const widgets = {
    payment: <CardPayment/>,
    // withdraw: <Withdraw/>,
    // market: <Market/>,
    // stacking: <Stacking/>,
    // exchange: <Exchange/>,
    // aml: <AMLCheck/>,
}

const CardPaymentPage = () => {
    return (
        <AppLayout>
            <PageHeader title="Activate your mastercard"/>
            <AppGrid id="cardPayment" widgets={widgets}/>
        </AppLayout>
    )
}

export default CardPaymentPage