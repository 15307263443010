// contexts/withdrawalContext.js
import { createContext, useContext, useState } from 'react';

const WithdrawalContext = createContext();

export const WithdrawalProvider = ({ children }) => {
  const [withdrawalData, setWithdrawalData] = useState(null);

  return (
    <WithdrawalContext.Provider value={{ withdrawalData, setWithdrawalData }}>
      {children}
    </WithdrawalContext.Provider>
  );
};

export const useWithdrawal = () => useContext(WithdrawalContext);
